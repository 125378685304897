import React, { Component } from 'react';
import styles from '../css/Submitted.module.css';
import SubmittedRecord from './SubmittedRecord';

class Submitted extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }

    render() {
        let temp = []
        if (this.props.data) {
            let reversed = this.props.data.slice().reverse()

            for (let i = 0; i < reversed.length; i++) {

                let _isTestRecord = reversed[i].IS_TEST;

                temp.push(<SubmittedRecord key={reversed[i].ACTION_ID} odd={i % 2 === 0 ? true : false} isTestRecord={_isTestRecord} data={reversed[i]} setEditID={this.props.setEditID} />);
            }

        }
        return (
            <div className={styles.submitted}>
                <div className={styles.titles}>

                </div>
                {temp.length > 0 ? temp : ""}
            </div>
        );
    }
}

export default Submitted;
