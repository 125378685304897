import React, {Component} from 'react';
import styles from '../css/SystemSettings.module.css';
import Axios from './../../../components/Axios'
import Toggle from '../../../globalcomponents/Toggle'

class SystemSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            edit: false,
            editIndex: -1,
            testEmail: '',
            testUser: '',
            testModeOn: false,
            emailOn: true

        };
    }

    componentDidMount() {
        let temp = {}
        temp.state = this.state
        Axios('/api/admin/get-system-settings', temp, (data) => {
            this.setState({
                loaded: true,
                testEmail: data.TEST_EMAIL,
                testUser: data.TEST_USER,
                testModeOn: data.IS_ACTIVE,
                emailOn: data.EMAIL_ON
            })
            //this.props.setData(data)
        })
    }

    updateSystemSettings() {

        let temp = {}
        temp.TEST_EMAIL = this.state.testEmail;
        temp.TEST_MODE_ON = this.state.testModeOn;
        temp.EMAIL_ON = this.state.emailOn;

        Axios('/api/admin/update-system-settings', temp, () => {
            alert('saved');
        })
    }


    componentDidUpdate(prevProps, prevState) {
        //if (prevProps.data !== this.props.data) {
        //    this.setState({data: this.props.data});
        //}
    }

  

    render() {
        let results = []
       
        return (
            <div className={styles.RegionalPlan}>
                
                <div className={styles.blueBar}>System Settings</div>
              
                <div className={styles.row}>
                    <div className={styles.titlecol}>
                        {'E&D Emails ON'}
                    </div>

                    <div className={styles.valuecol} title='Turn off/on E&D Emails'>
                        <Toggle active={this.state.emailOn} click={() => {

                            this.setState({ emailOn: !this.state.emailOn });
                           // this.updateSystemSettings();
                        }
                        } />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.titlecol}>
                        {'Test Mode'}
                    </div>

                    <div className={styles.valuecol} title='Test Mode will use the TEST Email'>
                        <Toggle active={this.state.testModeOn} click={() => {

                            this.setState({ testModeOn: !this.state.testModeOn });
                            //this.updateSystemSettings();
                        }
                        } />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.titlecol}>
                        {'Test Email'}
                    </div>

                    <div className={styles.valuecol} title='The email to send to when Test Mode is ON'>
                        <input type="text" className={styles.default} value={this.state.testEmail} onChange={(event) => {
                            this.setState({ testEmail: event.target.value })
                        }} autoFocus />
                       
                    </div>
                </div>
                {/*<div className={styles.row}>*/}
                {/*    <div className={styles.titlecol}>*/}
                {/*        {'Test User'}*/}
                {/*    </div>*/}

                {/*    <div className={styles.valuecol} title='The user configured to WRITE (SAVE DATA) in production'>*/}


                {/*        <input type="text" className={styles.default} value={this.state.testUser} disabled readOnly />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <hr/>
                <div className={styles.row}>
                    <div className={styles.titlecol}>
            <button className={styles.submit} onClick={() => {
                            this.updateSystemSettings();
                        }}>UPDATE</button>
                    </div>

                    <div className={styles.valuecol}>
                       
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default SystemSettings;
